import { Button, Container, Typography, Link as Linker } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../routes';
import { HomeStyles } from '../UseStyles';

function ThankYou() {

    const classes = HomeStyles();
    return (
        <div className={classes.root}>
            <Container>
                <Typography className={classes.heading} variant='h3'> Thank you for your message! </Typography>
                <Linker className={classes.link} color="inherit" component={ Link } to={ROUTES.KAICHIANDJOSHUA}>Submit another message</Linker>
            </Container>
        </div>
    )
}

export default ThankYou
