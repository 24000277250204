import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import db, { storage } from '../firebase';
import { Button, TextField, Grid, FormControl, RadioGroup, FormControlLabel, Radio, LinearProgress, Typography, Card, CardMedia } from '@material-ui/core';
import {v4 as uuid} from 'uuid';
import { FormStyles } from './NewStyles';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore"

//image size limiting


export default function MessageForm() {
  const classes = FormStyles();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [ps, setPs] = useState('');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState('');
  const [messageType, setMessageType] = useState ('');
  const [progress, setProgress] = useState(0);
  const [imagePreview, setImagePreview] = useState('');
  
  const onSubmit = (e) => {
    e.preventDefault();
    const id = uuid();

    const metadata = { contentType: 'image/jpeg' };
    const storageRef = ref(storage, 'images1/' + id);
    const uploadTask = uploadBytesResumable(storageRef, image, metadata);

    uploadTask.on('state_changed',
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress)
        },
        (error) => {
            console.log(error);
            alert(error.message);
        },
            // switch (snapshot.state) {
            //     case 'paused' :
            //         alert ('Upload is paused');
            //         console.log('Upload is paused');
            //         break;
            //     case 'running' :
            //         console.log('Upload is running');
            //         break;
            //     case 'storage/unknown' :
            //         alert ('Unknown error occurred');
            //         console.log('Unknown error occurred, inspect error.serverResponse');
            //         break;
            // }
        () => {
            getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadUrl) => {
                addDoc(collection(db, 'posts'), {
                    username, 
                    ps, 
                    image: downloadUrl,
                    message, 
                    messageType, 
                    timestamp: serverTimestamp(),
                    displayName: 'Joshua and Kaichi',  
                    email: 'joshandkaichi@gmail.com',
                    likes: false,
                })
                .catch((error) => alert(error.message)) 
                .then(() => {
                    setUsername('')
                    setPs('')
                    setMessage('')
                    setMessageType('')
                    setImage('')
                    downloadUrl=''
                    history.push('./thankyou')
                })
            })
        }
    );
}

const onChangeHandler = event => {
    const {name, value} = event.currentTarget;
    if (name === 'messageType') {
      setMessageType(value);
    } else if (name === 'username') {
      setUsername(value);
    } else if (name === 'ps') {
      setPs(value);
    } else if (name === 'message') {
      setMessage(value);
    }
};

  useEffect(()=>{
      if(!image){
          return;
      }
      const reader = new FileReader();
      reader.onload = ()=>{
        setImagePreview(reader.result);  
      }
      
      reader.readAsDataURL(image);
  },[image]);

  const handleChange = (e) => {
      if (e.target.files[0]) {
            setImage(e.target.files[0]);  
          }
    }

  const isInvalid =
  messageType === '' ||
  message === '' ||
  username === '' ||
  image ==='';

  return (
    <form className={classes.form} onSubmit={onSubmit}>
        <Grid item xs={12} className={classes.radio}>
            <FormControl component="fieldset">
                <RadioGroup row className= {classes.radio} aria-label="messageType" name="messageType" value={messageType} onChange={onChangeHandler}>
                    <FormControlLabel value="Wishing you both" control={<Radio />} label="Wishing you both" />
                    <FormControlLabel value="My marriage advice is" control={<Radio />} label="My marriage advice is" />
                </RadioGroup>
            </FormControl>
        </Grid>
        <Grid item container spacing={2}>
            <Grid item xs={12} >
            <TextField
                variant="outlined"
                required
                fullWidth
                id="message"
                label="Your message"
                name="message"
                value={message}
                multiline
                rows={3}
                onChange={event => onChangeHandler(event)}
            />
            </Grid>
            <Grid item xs={12} >
            <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="From:"
                name="username"
                value={username}
                onChange={event => onChangeHandler(event)}
            />
            </Grid>
            <Grid item xs={12} >
            <TextField
                variant="outlined"
                fullWidth
                name="ps"
                label="P.S."
                id="ps"
                value={ps}
                onChange={event => onChangeHandler(event)}
            />
            </Grid>
            <div className={classes.progress}>
                <LinearProgress variant="determinate" value={progress} max='100' />
                </div>
            {/* <div className={classes.imessage}>
                {image? (<p>Image Selected</p>) : ('')}
            </div> */}
            {image ? (<div className={classes.paper2}>
                        <Card className={classes.preview} backgroundColour='black'>
                            <CardMedia
                                component='img'
                                image={imagePreview} 
                                alt="Selected Image" />
                        </Card>
                    </div>) : ('')}
            {image ? (<Button
            fullWidth
            variant='outlined'
            color='primary'
            component='label'
            className={classes.upload}
            required
            > Change your picture *           
                <input
                    type="file"
                    hidden
                    accept="image/*"
                    required
                    onChange={handleChange}
                    />            
            </Button>) : (<Button
            fullWidth
            variant='outlined'
            color='primary'
            component='label'
            className={classes.upload}
            required
            > Select your picture *          
                <input
                    type="file"
                    hidden
                    accept="image/*"
                    required
                    onChange={handleChange}
                    />            
            </Button>)}
            <Typography className={classes.text}>Please use a landscape photo</Typography>
            <Button
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            className={classes.submit}
            disabled={isInvalid}
            >
            Send Message
        </Button>
        </Grid>
    </form>
  );
}