import { makeStyles } from '@material-ui/core';



const FormStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
    backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/our-guestbook.appspot.com/o/Logo%2Fbackground.jpg?alt=media&token=338d205a-488c-4365-be91-7346e5a21cbb')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  container: {
    marginTop: theme.spacing(2),
  },  
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  paper2: {
    marginTop: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
  },
  dear: {
    alignSelf: 'flex-start',
    fontFamily: 'Homemade Apple',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(1),
    fontSize: '20px',
    paddingRight: theme.spacing(3),
  },
  heading: {
    fontFamily: 'Homemade Apple',
    fontSize: '25px',
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(1),
  },
  subtext: {
    fontFamily: 'Assistant',
    width: '350px',
    textAlign: 'center',

  },
  text: {
    fontFamily: 'Assistant',
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    width: '300px',
    fontSize: '0.75rem',
  },
  image: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    height: '420px',
    width: '350px',
    border: 'none',
    objectFit: 'contain',
  },
  img: {
    height: '350px',
    width: '325px',
    objectFit: 'cover',
    margin: theme.spacing(1.5),

  },
  preview: {
    width: '100%',
    maxHeight: '250px',
    objectFit: 'cover',
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  radio: {
    fontSize: '10px',
    padding: theme.spacing(1),
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#66B2FF'
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Assistant',
    },
  },
  upload: {
    margin: theme.spacing(2, 0, 0),
    // backgroundColor: '#faf3f0',

    // '&:hover': {
    //   backgroundColor: '#fcfcd2',
    // },
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    // backgroundColor: '#456a8f',
    // color: 'white',

    // '&:hover': {
    //   backgroundColor: '#fcfcd2',
    //   color: 'black',
    // },
  },  
  link: {
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    textDecoration: 'strong',
  },
  input: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  progress: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),

    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#a8c5e3',
      '& .MuiLinearProgress-bar1Determinate.MuiLinearProgress-bar1Determinate': {
        backgroundColor: '#456a8f',
      }
    },
  }, 
  imessage: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
}));

const HomeStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(15),
  },
  heading: {
    paddingTop: '30px',
    fontFamily: 'Assistant',
    textAlign: 'center',
  },
  link: {
    fontFamily: 'Assistant',
    fontSize: '20px',
    textAlign: 'center',
    display: 'block',
    paddingTop: '30px',
  },  
  image: {
    width: '100%',
    objectFit: 'contain',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(5),
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(5),
    height: '50px',
    borderRadius: '15px',
    marginBottom: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '450px',
  },
  container: {
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 'auto',
    flexDirection: 'column',
    
    "@media (min-width: 450px)": {
      flexDirection: 'row'
    }
  },
  divider: {
    margin: theme.spacing(1),
    height: '50px',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    
    "@media (max-width: 450px)": {
      display: 'none',
    },
    
    "@media (min-width: 450px)": {
      flexDirection: 'row'
    },
  },
}));

const LoginStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1.5),
  },
  card: {
    marginTop: theme.spacing(10),
    width: '400px',
    height: '460px',
    alignContent: 'center',
  },
  container: {
    display: 'flex',
    width: '300px',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between'
  },
  form: {
    paddingTop: theme.spacing(3),
    width: '300px',
    display: 'block',
    margin: 'auto',
  },
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  heading: {
    paddingTop: '30px',
    fontFamily: 'Assistant',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '50px',
  },
  link: {
    fontFamily: 'Assistant',
    fontSize: '15px',
    textAlign: 'center',
    display: 'block',
    paddingTop: '10px',
    color: 'black',
  },  
  image: {
    height: '20px',
    width: '20px',
    objectFit: 'contain',
    marginRight: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(0.5),
  },
}));

const NavStyles = makeStyles((theme) => ({
  bar : {
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
    height: '100px',
  },
  padding : {
    paddingRight : 30,
    cursor : "pointer",
    fontFamily: 'Assistant',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  logo: {
    maxWidth: '300px',
    objectFit: 'contain',
    padding: '3px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  nav1: {
    display: "flex",
    height: '100px',
  },
}));

const PostStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxHeight: '350px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    objectFit: 'cover', 

    "@media (min-width: 450px)": {
      width: '50%',
    }
  },
  like: {
    display: 'flex',  
  },
  msg: {
    marginLeft: theme.spacing(1),
  },
  message: {
    minHeight: '20%',
    padding: theme.spacing(1),
    textAlign: 'left',
    lineHeight: 1.5,
    display: 'inline-block',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: '#86838a',

    "@media (min-width: 450px)": {
      width: '20%',
      
    }
  },
  message1: {
    minHeight: '20%',
    padding: theme.spacing(1),
    fontSize: '1rem',
    textAlign: 'left',
    lineHeight: 1.5,
    display: 'inline-block',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: '#4d4b4f',
    
    
    "@media (min-width: 450px)": {
      backgroundColor: '#f5ebf7',
      borderRadius: 10,
      flex: 1,
    }
    
  },
  messageType: {
    marginBottom: '10px',
  },
  from: {
    verticalAlign: 'bottom',
  },
  welcome: {
    color: 'black',
    margin: 'auto',
    paddingRight: theme.spacing(1),  
    fontWeight: 'bold',
  },
  signout: {
    margin: 'auto',
    height: '40px',
    width: '10rem',
  },
  bar: {
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const TableStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '98%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },  
  table: {
    minWidth: 650,
  },  
  button: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Assistant',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headerRow: {
    backgroundColor: 'black',
    justifyContent: 'left',

    '& .MuiTableCell-root': {
      color: 'white',
      fontFamily: 'Julius Sans One',
      padding: '0px',
      fontWeight: 'bold',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      padding: '1px 2px 0px 1px',
    },
  }
  
}));

const UseStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop: theme.spacing(5),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  confirmation: {
    textAlign: 'center',
    display: 'block',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  google: {
    margin: theme.spacing(0, 0, 2),
  },
  link: {
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    textDecoration: 'strong',
  },
}));

export { FormStyles, HomeStyles, PostStyles, TableStyles, LoginStyles, NavStyles }
export default UseStyles
