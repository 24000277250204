import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from'@material-ui/core';

import './Main.css';
import IanAndJanine from './IanAndJaninie';
import ThankYou from './ThankYou';
import Home from './Home';
import * as ROUTES from '../../routes';
import KaichAndJoshua from './JoshuaAndKaichi';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import PasswordSent from './PasswordSent';


function Main() {


  return (
    <div>
       <Container>
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route exact path={ROUTES.KAICHIANDJOSHUA} component={KaichAndJoshua} />
          <Route exact path={ROUTES.THANKYOU} component={ThankYou} />
          <Route exact path={ROUTES.SIGNIN} component={SignIn} />
          <Route exact path={ROUTES.SIGNUP} component={SignUp} />
          <Route exact path={ROUTES.RESET_PASSWORD} component={ForgotPassword} />
          <Route exact path={ROUTES.PASSWORD_SENT} component={PasswordSent} />
        </Container>
    </div>
  );
}

export default Main;
