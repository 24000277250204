import { makeStyles } from '@material-ui/core';

const UseStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      marginTop: theme.spacing(5),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    confirmation: {
      textAlign: 'center',
      display: 'block',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(1, 0, 2),
    },
    google: {
      margin: theme.spacing(0, 0, 2),
    },
    link: {
      cursor: 'pointer',
    },
    error: {
      color: 'red',
      textDecoration: 'strong',
    },
  }));

const CustomStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(12),
  },  
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper2: {
    marginTop: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
  },
  dear: {
    alignSelf: 'flex-start',
    fontFamily: 'Assistant',
    paddingTop: theme.spacing(5),
    fontSize: '20px',
    paddingRight: theme.spacing(3),
  },
  heading: {
    fontFamily: 'Hello Honey',
    fontSize: '60px',
  },
  subtext: {
    fontFamily: 'Assistant',
    paddingTop: theme.spacing(3),
    width: '300px',
  },
  text: {
    fontFamily: 'Assistant',
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    width: '300px',
    fontSize: '0.75rem',
  },
  image: {
    marginTop: theme.spacing(5),
    margin: theme.spacing(1),
    height: '300px',
    width: '300px',
    border: 'none',
    boxShadow: 'none',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    borderRadius: '10px',

  },
  preview: {
    width: '100%',
    maxHeight: '191px',
    objectFit: 'cover',
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  radio: {
    fontSize: '10px',
    padding: theme.spacing(1),

    '& .MuiFormControlLabel-label': {
      fontFamily: 'Assistant',
    },
  },
  upload: {
    margin: theme.spacing(2, 0, 0),
    // backgroundColor: '#faf3f0',

    // '&:hover': {
    //   backgroundColor: '#fcfcd2',
    // },
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    // backgroundColor: '#456a8f',
    // color: 'white',

    // '&:hover': {
    //   backgroundColor: '#fcfcd2',
    //   color: 'black',
    // },
  },  
  link: {
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    textDecoration: 'strong',
  },
  input: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  progress: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),

    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#a8c5e3',
      '& .MuiLinearProgress-bar1Determinate.MuiLinearProgress-bar1Determinate': {
        backgroundColor: '#456a8f',
      }
    },
  }, 
  imessage: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
}));

const PostStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '15px',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 850,
  },
  image: {
    width: '100%',
    height: 350,
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  like: {
    display: 'flex',  
  },
  message: {
    minHeight: '60%',
  },
  messageType: {
    marginBottom: '10px',
  },
  from: {
    verticalAlign: 'bottom',
  }
}));

const TableStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '98%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },  
  table: {
    minWidth: 650,
  },  
  button: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Assistant',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headerRow: {
    backgroundColor: 'black',
    justifyContent: 'left',

    '& .MuiTableCell-root': {
      color: 'white',
      fontFamily: 'Julius Sans One',
      padding: '0px',
      fontWeight: 'bold',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      padding: '1px 2px 0px 1px',
    },
  }
  
}));

  const HomeStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
      width: '100%',
    },
    heading: {
      paddingTop: '30px',
      fontFamily: 'Assistant',
      textAlign: 'center',
    },
    link: {
      fontFamily: 'Assistant',
      fontSize: '20px',
      textAlign: 'center',
      display: 'block',
      paddingTop: '30px',
    },  
    
  }));

const NavStyles = makeStyles((theme) => ({
  bar : {
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
  },
  padding : {
    paddingRight : 30,
    cursor : "pointer",
    fontFamily: 'Assistant',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  logo: {
    maxWidth: '300px',
    objectFit: 'contain',
    padding: '3px',
  }
  
}));


export { CustomStyles, PostStyles, TableStyles, HomeStyles, NavStyles }
export default UseStyles
