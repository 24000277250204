import React from 'react';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/navbar/Navbar';
import Main from './components/Pages/Main';
import { BrowserRouter as Router } from 'react-router-dom';



function App() {
  
  return (
    <div className="app">
      <Router>
        <Navbar />
          <Main />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
