import { AppBar, Button, ButtonBase, Container, Toolbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavStyles} from '../../components/NewStyles';
import { signout, useAuth } from '../../firebase';
import * as ROUTES from '../../routes';

function Navbar() {

    const classes = NavStyles();

    return (
    <AppBar className={classes.bar}>
        <Toolbar>
          <Container className={classes.nav1}>
            <ButtonBase className ={classes.title} component={ Link } to={ROUTES.HOME}>
              <img 
                className ={classes.logo} 
                src='https://firebasestorage.googleapis.com/v0/b/our-guestbook.appspot.com/o/Logo%2FLogo.png?alt=media&token=c7c32925-b7ca-4492-beee-4a39df681f3f' 
                alt='logo' 
                />
            </ButtonBase>
          </Container>
      </Toolbar>
    </AppBar>
    )
}

export default Navbar;