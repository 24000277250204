import { Button, Container, Divider, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { HomeStyles } from '../components/NewStyles';
import * as ROUTES from '../routes';
import { Link } from 'react-router-dom';

function HomeNoUser() {
    const classes = HomeStyles();

    return (
            <Grid container spacing={4} className={classes.container}>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <img 
                      className ={classes.image} 
                      src='https://firebasestorage.googleapis.com/v0/b/our-guestbook.appspot.com/o/Logo%2FLogo.png?alt=media&token=c7c32925-b7ca-4492-beee-4a39df681f3f' 
                      alt='logo' 
                  />
                  <Typography style={{fontFamily:'Homemade Apple'}}>Create your online Guestbook</Typography>
                </Paper>
              </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <Container className={classes.container}>
                  <Button 
                    fullWidth
                    size='large'
                    className={classes.button} 
                    component={ Link } 
                    to={ROUTES.SIGNUP}>
                      Sign Up
                  </Button>
                  <Divider className={classes.divider} orientation= 'vertical' flexItem= 'true'/>
                  <Button 
                    fullWidth
                    className={classes.button} 
                    component={ Link } 
                    to={ROUTES.SIGNIN}>
                      Sign In
                  </Button>
                  <Divider className={classes.divider} orientation= 'vertical' flexItem= 'true'/>
                  <Button 
                    fullWidth
                    className={classes.button} 
                    component={ Link } 
                    to={ROUTES.CONTACT}>
                      Contact Us
                  </Button>
                </Container>
              </Paper>
            </Grid>
            </Grid> 
    )
}

export default HomeNoUser
