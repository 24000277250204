import { Button, Card, Container, Typography, TextField, Link as Links } from '@material-ui/core'
import React, { useState } from 'react'
import { LoginStyles } from '../NewStyles'
import { signInWithGoogle, signin, useAuth } from '../../firebase';
import { Link, Redirect } from 'react-router-dom';
import * as ROUTES from '../../routes';

function SignIn() {
    const classes = LoginStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const currentUser = useAuth();

    async function handleSignIn() {
        setLoading(true);
        try{
            await signin(email, password);
        } catch (error) {
            alert(error.message);
            console.log(error.message);
        }
        setLoading(false);
    }
    if (currentUser) {
        return <Redirect to='/'/>
    }

    const onChangeHandler = event => {
        const {id, value} = event.currentTarget;
        if (id === 'email') {
          setEmail(value);
        } else if (id === 'password') {
          setPassword(value);
        } 
    };

    const isInvalid =
    email === '' ||
    password === '';

    return (
    <Container className={classes.root} component="main" maxWidth="md">
        <Card className={classes.card}>
            <Typography className={classes.heading} component="h1" variant="h5">
                SIGN IN
            </Typography>
            <form className={classes.form} onSubmit={null}>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    type= "email"
                    value={email}
                    onChange={onChangeHandler}
                />
                <TextField
                    className={classes.input}
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={onChangeHandler}
                />
                <Button
                fullWidth
                type='submit'
                variant='outlined'
                component='label'
                className={classes.button}
                onClick={handleSignIn}
                disabled={loading || isInvalid }
                > Sign In         
                </Button>
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => signInWithGoogle()}
                    className={classes.button}>
                        <img 
                        className ={classes.image} 
                        src='https://firebasestorage.googleapis.com/v0/b/our-guestbook.appspot.com/o/Logo%2Fgoogle.png?alt=media&token=b83a019b-2db0-4629-b703-4586058f2d06' 
                        alt='google' 
                        />
                        Sign In with Google
                </Button>
            </form>
            <Container className={classes.container}>
                <Links
                    className={classes.link}
                    underline='hover'
                    component={ Link } 
                    to={ROUTES.RESET_PASSWORD}>
                    Forgot Password?
                </Links>
                <Links
                    className={classes.link}
                    underline='hover'
                    component={ Link } 
                    to={ROUTES.SIGNUP}>
                    Sign Up
                </Links>
            </Container>
        </Card>
    </Container>
    )
}

export default SignIn
