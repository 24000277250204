import React from 'react';
import { CssBaseline, Grid, Typography, Container, Card } from '@material-ui/core';
import MessageForm from '../MessageForm';
import { FormStyles } from '../NewStyles';


export default function JoshuaAndKaichi() {
  const classes = FormStyles();

  return (
    <Container className={classes.root} component="main" maxWidth="md">
      <CssBaseline />
      <Grid container spacing={5} className={classes.container}>
        <Grid item xs={12} sm={6} >
            <div className={classes.paper}>
                <Card className={classes.image}>
                <img className={classes.img} src='https://firebasestorage.googleapis.com/v0/b/our-guestbook.appspot.com/o/Logo%2FKaichi_Joshua.jpg?alt=media&token=5c58817c-8c09-4a90-ab77-2330e9ef95db' alt=''/>
                <Typography className={classes.subtext} style={{fontFamily:'Homemade Apple', fontSize: '15px', fontWeight: 'bold'}} >
                    Thank you for attending our wedding!
                </Typography>
                </Card>
                <Typography className={classes.subtext} style={{paddingTop: '10px'}}>
                    Please fill out our guestbook form below and upload your photo. We will make a guestbook for ourselves.
                </Typography>
            </div>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={2} direction='column' >
            <div className={classes.paper2}>
                <Typography className={classes.heading} component="h1" variant="h5">
                    Dear Joshua & Kaichi,
                </Typography>
            </div>
            <MessageForm />
        </Grid>
      </Grid>
    </Container>
  );
}


// form validation