import React from 'react';
import { Container, Button, CssBaseline, Grid, Paper, Typography } from'@material-ui/core';
import { HomeStyles } from '../NewStyles';
import * as ROUTES from '../../routes';
import { Link } from 'react-router-dom';
import HomeNoUser from '../HomeNoUser';
import { doc, onSnapshot } from 'firebase/firestore';
import HomeUser from '../HomeUser';
import { useAuth } from '../../firebase';

function Home() {
  const classes = HomeStyles();
  const currentUser = useAuth();

  return (
    <Container className={classes.root}>
       <CssBaseline />
          {currentUser? (
          <HomeUser />) : (
           <HomeNoUser />
          )}
    </Container>
  );
}

export default Home;
