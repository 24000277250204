export const SIGNUP = '/sign-up';
export const SIGNIN = '/sign-in';
export const ADMIN = '/admin';
export const RESET_PASSWORD = '/reset-password';
export const PASSWORD_SENT = '/password-reset-sent';
export const ADMIN_DETAILS = '/admin/:id';
export const POSTS = '/posts';
export const PROFILE = '/profile';
export const FORM = '/form/:id';
export const SAVED = '/saved-posts/ ';
export const ABOUT = '/about';
export const DASHBOARD = '/dashboard';
export const HOME = '/';
export const SERVICES = '/services';
export const CONTACT = '/contact-us';
export const IANANDJANINE = '/ianandjanine2021';
export const KAICHIANDJOSHUA = '/joshuaandkaichi2022';
export const THANKYOU = '/thankyou';
export const SIGNUPTHANKYOU = '/thank-you-for-signing-up'