import { Container, CssBaseline, Typography } from '@material-ui/core'
import React from 'react'
import { HomeStyles } from '../NewStyles';

function PasswordSent() {

    const classes = HomeStyles();

    return (
        <Container className={classes.root} component="main" maxWidth="md">
            <CssBaseline />
            <Typography className={classes.heading}>Password Reset Sent! Please check email.</Typography>
        </Container>
    )
}

export default PasswordSent
