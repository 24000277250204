import { Button, Card, Container, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router';
import { forgotPassword, useAuth } from '../../firebase';
import { LoginStyles } from '../NewStyles';

function ForgotPassword() {

    const classes = LoginStyles();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const currentUser = useAuth();
    const history = useHistory();

    async function handleForgotPW() {
        setLoading(true);
        try{
            await forgotPassword(email);
        } catch (error) {
            alert(error.message);
            console.log(error.message);
        }
        setLoading(false);
        history.push('/password-reset-sent');
    }
    if (currentUser) {
        return <Redirect to='/'/>
    }

    const onChangeHandler = event => {
        const {id, value} = event.currentTarget;
        if (id === 'email') {
          setEmail(value);
        }
    };

    const isInvalid =
    email === '';

    return (
        <Container className={classes.root} component="main" maxWidth="md">
        <Card className={classes.card}>
            <Typography className={classes.heading} style={{fontSize: '40px'}}>
                RESET PASSWORD
            </Typography>
            <form className={classes.form} onSubmit={null}>
                <TextField
                    className={classes.input}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    type= "email"
                    value={email}
                    onChange={onChangeHandler}
                />
               <Button
                    fullWidth
                    variant='outlined'
                    component='label'
                    className={classes.button}
                    onClick={handleForgotPW}
                    disabled={loading || isInvalid }
                    > Reset Password         
                </Button>
            </form>
            </Card>
    </Container>
    )
}

export default ForgotPassword
