import { Button, Container, CssBaseline, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { PostStyles } from './NewStyles';
import db, { signout } from '../firebase';
import { collection, onSnapshot, query, where, orderBy } from '@firebase/firestore';
import Post from './Post';
import { getAuth } from '@firebase/auth';
import FlipPost from './FlipPost';

function HomeUser() {
    const user = getAuth().currentUser;
    const classes = PostStyles();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    async function handleSignOut(){
      setLoading(true);
      try {
        await signout();
      } catch(error) {
          alert(error.message);
      }
      setLoading(false);
    }
    
    const q = query(collection(db, 'posts'), where("email", "==", user.email), orderBy('timestamp', 'desc'))

    useEffect(() => {
      if (user) {
        onSnapshot(q, (snapshot) =>
        setPosts(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))))
      } else {
      }
    }, []);
    

    return (
      <Container className={classes.root} component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.bar}>
            <Typography className={classes.welcome}>Welcome {user.email}!</Typography>
              <Button 
                className={classes.signout}
                variant='outlined'
                onClick={handleSignOut}
                disable={loading || !user}
                > SIGN OUT
              </Button>
            </div>
        {posts.map((post) => (
                            <FlipPost
                                key={post.id}
                                profilePic={post.data.profilePic}
                                image={post.data.image}
                                messageType={post.data.messageType}
                                message={post.data.message}
                                timestamp={post.data.timestamp}
                                username={post.data.username}
                                likes={post.data.likes}
                                id={post.id}
                                ps={post.data.ps}
                                displayName={post.data.displayName}
                                email={post.data.email}
                            />
                         ))}
      </Container>     
    )
}

export default HomeUser
