import React, { useState } from 'react';
import BookmarkSharpIcon from '@material-ui/icons/BookmarkSharp';
import BookmarkBorderSharpIcon from '@material-ui/icons/BookmarkBorderSharp';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ReactCardFlip from 'react-card-flip';

import { PostStyles } from './NewStyles';
import { ButtonBase, Divider } from '@material-ui/core';



function FlipPost({
    image, 
    username,
    messageType, 
    timestamp, 
    message, 
    likes, 
    id,
    ps,
    displayName,
    email,
}) {
    
    const classes = PostStyles();
    const [isFlipped, setIsFlipped] = useState(false);

    // const handleClick = (e) => {
    //     e.preventDefault();
    //     if (isFlipped) {
    //         setIsFlipped(true)
    //     } else {
    //         setIsFlipped(false)
    //     }
    //   }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal'>
            <ButtonBase onClick={() =>setIsFlipped(true)}  className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={1} className={classes.image}>
                            <Grid item xs={12} y>
                                <img className={classes.img} alt='' src={image} />
                            </Grid>
                        </Grid>
                    </Paper>
            </ButtonBase>
            <ButtonBase onClick={() =>setIsFlipped(false)} className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm container className={classes.msg}>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs container direction="row">
                                    <Typography className={classes.message}>
                                    Dear 
                                    </Typography>
                                    <Typography className={classes.message1}>
                                    {displayName},
                                    </Typography>
                                </Grid>    
                                <Grid item xs container direction="row">
                                    <Typography className={classes.message}>
                                    {messageType}:
                                    </Typography>
                                    <Typography className={classes.message1}>
                                    {message}
                                    </Typography>
                                </Grid>
                                <Grid item xs container direction='row'>
                                    <Typography className={classes.message}>
                                    From: 
                                    </Typography>
                                    <Typography className={classes.message1}>
                                    {username}
                                    </Typography>
                                </Grid>
                                {ps? <Grid item xs container direction='row'>
                                    <Divider variant='fulWidth' />
                                    <Typography className={classes.message}>
                                        P.S. :
                                    </Typography>
                                    <Typography className={classes.message1}>
                                        {ps}
                                    </Typography>
                                </Grid>
                            : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </ButtonBase>
        </ReactCardFlip>
        // <div className={classes.root}>
        // 
        //     <Grid container spacing={2}>
        //         <Grid item xs={12} sm md lg className={classes.image}>
        //             <img className={classes.img} alt='' src={image} />
        //         </Grid>
        //         <Grid item xs={12} sm container className={classes.msg}>
        //             <Grid item xs container direction="column" spacing={2}>
        //                 <Grid item xs container direction="row">
        //                     <Typography className={classes.message}>
        //                     Dear 
        //                     </Typography>
        //                     <Typography className={classes.message1}>
        //                     {displayName},
        //                     </Typography>
        //                 </Grid>    
        //                 <Grid item xs>
        //                     <Typography className={classes.message}>
        //                     {messageType}:
        //                     </Typography>
        //                     <Typography className={classes.message1}>
        //                     {message}
        //                     </Typography>
        //                 </Grid>
        //                 <Grid item xs container direction='row'>
        //                     <Typography className={classes.message}>
        //                     From: 
        //                     </Typography>
        //                     <Typography className={classes.message1}>
        //                     {username}
        //                     </Typography>
        //                 </Grid>
                        
        //                     {ps? <Grid item xs container direction='row'>
        //                             <Typography className={classes.message}>
        //                                 P.S. :
        //                             </Typography>
        //                             <Typography className={classes.message1}>
        //                                 {ps}
        //                             </Typography>
        //                         </Grid>
        //                     : ''}
        //                 {/* <Grid item>
        //                     <Typography variant="body2">
        //                     {new Date(timestamp?.toDate()).toUTCString()}
        //                     </Typography>
        //                 </Grid> */}
        //             </Grid>
        //         </Grid>
        //     </Grid>
        // </Paper>
        // </div>














        // <div className= 'post'>
        //     <div className='post__image'>
        //         <img src={image} alt='' />
        //     </div>
        //      <div className= 'post__top'>
        //         <div className='post__mtype'>
        //             <p>{messageType}</p>
        //         </div>
        //         <div className= 'post__message'>
        //             <p>{message}</p>
        //         </div>
        //         <div className= 'post__topInfo'>
        //             <h4>From: {username}</h4>
        //             <p>{new Date(timestamp?.toDate()).toUTCString()}</p>
        //         </div>          
        //         <div className= 'post__options'>
        //             <div className="post__option">
        //                 {/* <span>{getLikes(likes)}</span> */}
        //                 {likes? <BookmarkSharpIcon onClick={cancelLikes} /> :
        //                 <BookmarkBorderSharpIcon onClick={setLikes} />}                  
        //                 <p>Love It!</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default FlipPost
